import React from 'react';
import {
    Row,
    Col,
    Container,
} from 'reactstrap';
import TermsandConditions from '../assets/files/Terms_and_Conditions.pdf';
import PrivacyPolicy from '../assets/files/Privacy_Policy.pdf';

const FooterBar = (props) => {
    const { image1, image2, vline } = props
    return (
        <footer>
            <Container>
                <Row className='align-items-center'>
                    <Col className="d-flex justify-content-start">
                        <img
                            src={image1}
                            alt="hm_image"
                            style={{ width: "10em", height: "auto" }}
                        />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <img
                            src={image2}
                            alt="footer_image"
                            style={{ width: "3em", height: "auto" }}
                        />
                    </Col>
                </Row>
                <hr className="footer-divider" />
                <Row className='footer quicksand-normal-white'>
                    <Col className="d-flex justify-content-start">
                        <a href={""} className="footer-link" target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href={PrivacyPolicy} className="footer-link" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href="/news" className="footer-link">News</a>
                        <img
                            className='footer-divider-vertical'
                            src={vline}
                            alt='seperator line'
                        />
                        <a href="https://complaint.healthmoni.com/" className="footer-link" target="_blank" rel="noopener noreferrer">Complaints</a>
                    </Col> 
                </Row>
                <hr className="footer-divider" />
                <Row>
                    <Col className='quicksand-normal-white footer-padding'>
                    HealthMoni Limited is registered in England No. 11522723. Registered Office:  128 City Road, London, United Kingdom, EC1V 2NX.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default FooterBar;