import React, { useState, useEffect } from 'react';
import {
    Container,
    Row,
    Col,
} from 'reactstrap';
import ContentTop from '../common/ContentTop';
import contactusTopImage from '../assets/img/shutterstock-2146286165-2.png';
import ContactUsCard from '../components/contactUs/ContactUsCard';
import {BLACK_SQUEEZE_COLOR, ALTO_COLOR} from '../common/Constants';
import cardImage1 from '../assets/img/frame.svg';
import cardImage2 from '../assets/img/local_government.png';
import cardImage3 from '../assets/img/investor.png';
import cardImage4 from '../assets/img/frame-1.svg';
import positioningPaper from '../assets/files/Positioning_Paper.pdf';
import FooterBar from '../common/Footer';
import FooterImage1 from '../assets/img/hm-logo-white-rgb-7@2x.png';
import FooterImage2 from '../assets/img/image-224@2x.png';
import vline from '../assets/img/line-634.svg';


const ContactUs = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
        setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const contact_data = [
        {
            title: 'Customer Enquiries',
            description: 'If you’re a HealthMoni user, you can get in touch using the email below or directly through the HealthMoni app.',
            bgcolor: BLACK_SQUEEZE_COLOR,
            source: 'mailto:support@healthmoni.com',
            linkText: 'support@healthmoni.com',
            cardImage: cardImage1
        },
        {
            title: 'Local Government',
            description: 'HealthMoni works with a small number of local authorities to develop our digital solutions. If you’re from local government and interested in HealthMoni, please get in touch using the email address below.',
            bgcolor: ALTO_COLOR,
            source: 'mailto:relations@healthmoni.com',
            linkText: 'relations@healthmoni.com',
            cardImage: cardImage2
        },
        {
            title: 'Investors',
            // description: 'HealthMoni is currently funded by Innovate UK and Founder investment. Our paper - Can fintech help Social Care? underpins our ambition to develop a new type of financial institution, become a leading ESG and EDI business and attain B Corporation status. We’re interested in talking to institutional investors, especially those with experience in public/private enterprise and technology with a social purpose.',
            descriptionLink: positioningPaper,
            bgcolor: ALTO_COLOR,
            source: 'mailto:inversor@healthmoni.com',
            linkText: 'investor@healthmoni.com',
            cardImage: cardImage3
        },
        {
            title: 'Recruitment',
            description: 'HealthMoni is a growing company and we’re particularly interested in building expertise in digital technology and financial systems integration. If you these skills and want to make a positive impact on society, we want to hear from you.',
            bgcolor: ALTO_COLOR,
            source: 'mailto:employment@healthmoni.com',
            linkText: 'employment@healthmoni.com',
            cardImage: cardImage4
        }
    ];

    return (
        <Container fluid >
            <ContentTop title={'Contact us '} 
                subtitle={'for user support, opportunities to work with us, and everything in between.'} 
                description={'We’re here to support our existing customers and open to exploring partnership opportunities with other organisations.'} 
                image={contactusTopImage} />
            {width <=1024 ? ( <>
            {contact_data.map((item, index) => (
                <ContactUsCard key={index}
                title={item.title}
                description={item.description}
                descriptionLink={item.descriptionLink}
                bgcolor = {item.bgcolor}
                source = {item.source}
                cardImage = {item.cardImage}
                linkText = {item.linkText}
                />
                )) }
                </>) : (<Container>
                <Row>                   
                    {contact_data.map((item, index) => (
                        <Col sm='6' key={index} >
                        <ContactUsCard key={index}
                        title={item.title}
                        description={item.description}
                        descriptionLink={item.descriptionLink}
                        bgcolor = {item.bgcolor}
                        source = {item.source}
                        cardImage = {item.cardImage}
                        linkText = {item.linkText}
                        />
                        </Col>
                        )) 
                    }                   
                </Row>
                </Container>)}
            <Row className='footer-container' style={{marginTop: '4em'}}>
                <FooterBar image1={FooterImage1}
                    image2={FooterImage2}
                    vline={vline}/>
            </Row>  

        </Container>
    )
}

export default ContactUs;